import Loader from "../../assets/svgs/loader.svg"

export default function InlineLoader(props) {
    return (
        // <div className="">
        <img
            className="align-self-center"
            style={{ height: "width" in props ? props.width : "100%" }}
            src={Loader}
        />
        // </div>
    );
}