import { unAuthorizedSignout } from "./users"

let excludeRoutes = ["signin", "updatepassword", "verify-email", "sign-out"]
export const Handling = (error, url) => {
    // let url1 = url.split(/[/ ]+/).pop()
    const regex = new RegExp(`\\b(${excludeRoutes.join('|')})\\b`, 'i');
    if (regex.test(url)) {
        return error.response
    } else {
        if (error.response.data.statusCode === 401) {
            unAuthorizedSignout()
        } else {
            return error.response
        }
    }
}
