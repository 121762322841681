import React, { useState, useEffect } from "react";

function ConnectionStatus(props) {
    const [isConnected, setIsConnected] = useState(true);

    useEffect(() => {
        const handleConnectionChange = () => {
            setIsConnected(navigator.onLine);
            if ("ConnectionStatus" in props) {
                props.ConnectionStatus(navigator.onLine)
            }
        };

        window.addEventListener("online", handleConnectionChange);
        window.addEventListener("offline", handleConnectionChange);
        if ("ConnectionStatus" in props) {
            props.ConnectionStatus(navigator.onLine)
        }
        return () => {
            window.removeEventListener("online", handleConnectionChange);
            window.removeEventListener("offline", handleConnectionChange);
        };
    }, []);

    return (
        null
        // <div>
        //     {isConnected ? (
        //         <p>You are connected to the internet.</p>
        //     ) : (
        //         <p>You are not connected to the internet.</p>
        //     )}
        // </div>
    );
}

export default ConnectionStatus;