import Logo from "../../assets/svgs/logo.svg";

export default function defaultHeader() {
  return (

    <div className="footer text-unselectable row m-0">
      <div className="col-md text-md-right text-center">
        2016 - {new Date().getFullYear()} © Copyright by
      </div>
      <div>
        <a href="http://linked-things.com/" target="_blank"><img src={Logo} /></a>
      </div>
      <div className="col-md text-md-left text-center">
        All rights are reserved
      </div>
    </div>
  );
}
