import axios from 'axios';
import cookies from './cookies';
import { Handling } from './errorHandling';



export const postRequest = async (url, body) => {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    try {
        const response = await axios.post(url, body);
        return response
    } catch (error) {
        let response = Handling(error, url)
        return response
    }
}
export const getRequest = async (url) => {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    try {
        const response = await axios.get(url);
        return response
    } catch (error) {
        let response = Handling(error, url)
        return response
    }
}

export const putRequest = async (url, body) => {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    try {
        const response = await axios.put(url, body);
        return response
    } catch (error) {
        let response = Handling(error, url)
        return response
    }
}

export const deleteRequest = async (url, body) => {
    let token = cookies.getCookie('token');
    axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    try {
        const response = await axios.delete(url);
        return response
    } catch (error) {
        let response = Handling(error, url)
        return response
    }
}

// import React from 'react';
// import ReactDOM from 'react-dom';
// import axios from 'axios';
// import cors from 'cors';

// // import Mypopup from '../../components/popup/mypopup';
// import SignoutPopup from '../../components/signoutPopup/signoutPopup';
// import cookies from './cookies';
// import { signOut, unAuthorizedSignout } from './users';

// const axios = axios1.create()
// axios.use(cors());
// axios.defaults.headers.withCredentials = true;

// export const postRequest = async (url, body, csrf = null, header = null) => {
//     // axios.use(cors());


//     // let token = cookies.getCookie('token');
//     // let token = document.head.querySelector('meta[name="X-CSRF-Token"]')
//     // axios.defaults.headers['Authorization'] = 'Bearer ' + token;
//     // axios.defaults["credentials"] = "include";
//     if (csrf) {
//         axios.defaults.headers['X-CSRF-Token'] = csrf
//     }
//     if (header) {
//         axios.defaults.headers = header
//     }
//     try {
//         const response = await axios.post(url, body, { withCredentials: true });
//         return response
//     } catch (error) {
//         if (error.response.data.statusCode === 401) {
//             // Create a container element to render the React component into
//             // Render the React component into the container
//             unAuthorizedSignout()
//             // ReactDOM.render(<SignoutPopup />);
//             // Append the container to the DOM or do whatever you need to do with it
//         } else {
//             return error.response
//         }
//     };
//     // let response = await axios.post(url, body, { withCredentials: true })
//     // if (response.data.message === "Unauthorized") {
//     //     alert("401 error")
//     //     signOut()
//     // } else {
//     //     return response
//     // }
// }


// export const getRequest = async (url) => {
//     // axios.use(cors());

//     // try {
//     //     let response = await axios.get(url, { withCredentials: true })
//     //     return response
//     // } catch (error) {
//     //     if (error.response.status === 401) {
//     //         signOut()
//     //     } else {
//     //         return error
//     //     }
//     // };
//     try {
//         const response = await axios.get(url, { withCredentials: true });
//         return response
//     } catch (error) {\
//         if (error.response.data.statusCode === 401) {
//             // window.onload = function () {
//             //     let element = document.getElementById('authPopup')
//             //     ReactDOM.render(<SignoutPopup />, element);
//             // }
//             // signOut()
//             unAuthorizedSignout()
//         } else {
//             return error.response
//         }

//     };
// }

// export const putRequest = async (url, body) => {
//     // axios.use(cors());

//     try {
//         const response = await axios.put(url, body, { withCredentials: true });
//         return response
//     } catch (error) {
//         if (error.response.data.statusCode === 401) {
//             // Create a container element to render the React component into
//             // Render the React component into the container
//             unAuthorizedSignout()
//             // ReactDOM.render(<SignoutPopup />);
//             // Append the container to the DOM or do whatever you need to do with it
//         } else {
//             return error.response
//         }
//     };
// }
// export const deleteRequest = async (url, body) => {
//     // axios.use(cors());

//     try {
//         const response = await axios.delete(url, { withCredentials: true });
//         return response
//     } catch (error) {
//         if (error.response.data.statusCode === 401) {
//             // Create a container element to render the React component into
//             // Render the React component into the container
//             unAuthorizedSignout()
//             // ReactDOM.render(<SignoutPopup />);
//             // Append the container to the DOM or do whatever you need to do with it
//         } else {
//             return error.response
//         }
//     }
//     // let token = cookies.getCookie('token');
//     // axios.defaults.headers['Authorization'] = 'Bearer ' + token;
// }
