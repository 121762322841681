import Loader from "../../assets/svgs/loader.svg"

export default function CardLoader() {
    return (
        <div className="d-flex w-100 h-100 justify-content-center">
            <img
                className="align-self-center"
                style={{ height: "100px" }}
                src={Loader}
            />
        </div>
    );
}