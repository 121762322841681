export const EmailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const WhiteSpace = /\s/g
export const CapitalLetters = /^(?=.*?[A-Z])/
export const SmallLetters = /(?=.*?[a-z])/
export const Numbers = /(?=.*?[0-9])/
export const SpecialCharacters = /[!@#\$%\^&\*\(\)_\+\-=\{\}\[\]\\\|:;\"'<>,\.\?\/]/g
export const SpecialCharacters2 = /(?=.*?[^\w\s])/
export const ExcludeAlphabets = /[^a-zA-Z]/g
export const CompanyUrl = /\b(?: https ?: \/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?\b/

// export const SimpleEmailregex =  /^[\w-\.]+@([\w -]+\.)+[\w-]{2,4}$/
