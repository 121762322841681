import { useEffect } from 'react'
import { connect } from 'react-redux'

const AccessValidation = (props) => {
    useEffect(() => {
        let heirarchy = props.userInfo?.varification?.hierarchy
        let levelId = props.levelId
        let orgId = levelId?.split("_")[0]
        let branches = false
        let access = []
        if (heirarchy) {
            heirarchy.map((levels, key) => {
                if (orgId === levels._id) {
                    if (levels.branches.length) {
                        levels.branches.map(branch => {
                            if (levelId.match(branch.levelId)) {
                                branches = true
                                branch.roleId.access?.map(acc => {
                                    access.push(acc.value)
                                })
                            }
                        })
                    }
                    if (!branches) {
                        access = levels?.access[0]?.value
                    }
                }
            })
            if ("onAccessHandler" in props) {
                props.onAccessHandler(access)
            }
        }
    }, [props.levelId])

    return null

    //   return (
    //   )
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo
})
export default connect(mapStateToProps, null)(AccessValidation)