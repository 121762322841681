import React, { useEffect, useState } from 'react'
import './signal.scss'

const Signal = (props) => {
    let [signal, setSignal] = useState(0)

    useEffect(() => {
        if ("connection" in props) {
            setSignal(props.connection)
        }
    }, [props.connection])
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1"
            viewBox="0 0 882.11 695.12"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <line className="signal0 signal_str0 active" x1="37.34" y1="524.52" x2="37.34" y2="680.18" />
                <line className="signal0 signal_str0 active" x1="240.81" y1="409.42" x2="240.81" y2="680.18" />
                <line className="signal0 signal_str0 active" x1="444.28" y1="280.03" x2="444.28" y2="680.18" />
                <line className="signal0 signal_str0 active" x1="647.75" y1="151.99" x2="647.75" y2="680.18" />
                <line className="signal0 signal_str0 active" x1="842.12" y1="23.96" x2="842.12" y2="680.18" />
                {!signal ?
                    <>
                        <line className="signal0 signal_str1" x1="18.35" y1="23.96" x2="863.77" y2="671.16" />
                        <line className="signal0 signal_str0 active" x1="18.35" y1="23.96" x2="863.77" y2="671.16" />
                    </>
                    : null}
            </g>
        </svg>
    )
}

export default Signal